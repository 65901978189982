import   React     , 
       { useEffect ,
	     useState  }  from 'react';

import   classNames                                from 'classnames';

import Slider  from 'react-slick';
import "./slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from './topten.module.scss';

import   RankedThumbnail   from './ranked_thumbnail'

import {getVideoViewsNumberRanking , RankedVideo} from '../../../../../network/api_video'

import { useTypedSelector }  from '../../../../../datas/useTypeSelector';

import {Video             }  from '../../../../../datas/videoManagment/actionTypes'

export interface TopTenProps {
  showSheet: ( video: Video ) => void
  showZoom:  ( video:  Video  ,
               width:  number ,
			   height: number ,
			   top:    number ,
			   left:   number ) => void
  showAlertWindowDeprecatedToken: () => void;			   
}

export const TopTen = ({ showSheet ,
						 showZoom  ,
						 showAlertWindowDeprecatedToken }: TopTenProps) => {

  const { catalog } = useTypedSelector ( (state) => state.videoManagment );

  const emptyList: Video [] = []
  
  const [ first  , setFirst  ] = useState(false);
  const [ rankedVideoList , setRankedVideoList ] = useState ( emptyList );
    const [ nbSlides , setNbSlides ] = useState(3);


  let settings = {
    infinite:       false,
	arrows:         true,
    speed:          500,
    slidesToShow:   nbSlides,
    slidesToScroll: nbSlides,
    initialSlide:   0
  };
  
  
  
   useEffect(() => {

    const handleResize = () => {

	       if ( window.innerWidth < 1110 ) { setNbSlides (1) }
	  else if ( window.innerWidth < 1630 ) { setNbSlides (2) }
	  else if ( window.innerWidth < 2155 ) { setNbSlides (3) }
	  else if ( window.innerWidth < 2680 ) { setNbSlides (4) }
	  
	  else if ( window.innerWidth < 3225 ) { setNbSlides (5) }
	  else if ( window.innerWidth < 3742 ) { setNbSlides (6) }
	  else if ( window.innerWidth < 4020 ) { setNbSlides (7) }
	  else                                 { setNbSlides (8) }
	  
	  
	settings = {
       infinite:       false,
	   arrows:         true,
       speed:          500,
       slidesToShow:   nbSlides,
       slidesToScroll: nbSlides,
       initialSlide:   0
      };
    };

	 {
	  setFirst (true);
	  
	  handleResize();
	  
	  init ()
	 }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
	
  }, [first]);

/*	
  useEffect(() => {
	  init ()
  }, []);
*/

  useEffect(() => {

  }, [rankedVideoList]);






// Fonction pour récupérer et trier les vidéos en évitant les doublons
  const getUniqueVideosSortedByViews = (): Video[] => {
    let allVideos: Video[] = [];
    const videoIdsSet = new Set<number>(); // Utiliser un Set pour suivre les id des vidéos déjà rencontrées

    // Extraire toutes les vidéos sans doublons
    catalog.categories.forEach((category) => {
      category.videoSets.forEach((videoSet) => {
        videoSet.videos.forEach((video) => {
          if (!videoIdsSet.has(video.id)) {
            allVideos.push(video);
            videoIdsSet.add(video.id); // Ajouter l'id au Set pour éviter les doublons
          }
        });
      });
    });

    // Trier les vidéos par `numberOfViews` en ordre décroissant
    return allVideos.sort((a, b) => (b.numberOfViews+b.numberOfViewsFromYoutube) - (a.numberOfViews+a.numberOfViewsFromYoutube));
  };
  
  const init = () => {
    
    const list = getUniqueVideosSortedByViews () 
		
	setRankedVideoList ( list )
  }

  /*
  const init = async () => {
  
    const result = await getVideoViewsNumberRanking () 
	
	console.log ( result )
	
	setRankedVideoList ( result.ranking )
  }
*/

  const thumbnails = ( limit: number ) : JSX.Element => {

    const thumbnails = [];

    for ( let i=0; i<limit && i<rankedVideoList.length; i++ )
	 {
	  thumbnails.push ( <div><RankedThumbnail rank      = {i+1} 
		                                      video     = {rankedVideoList[i]} 
		                                      showSheet = {showSheet} 
			                                  showZoom  = {showZoom} 
											  showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} /></div>);
	 } 
	 
    return <Slider {...settings}>{thumbnails}</Slider>	 
  }
  
  return (
 
           <div className={classNames(styles.root)}>

            <p  className={classNames(styles.title)}>
			 Top 10 des vidéos
            </p>

            <div  className={classNames(styles.carousel)}>
			  
			  { thumbnails (10) }

            </div>
				
           </div>
 
  );
};

export default TopTen;

