import   React              ,
       { useEffect          ,
	     useState           }  from 'react';
		 
import   classNames            from 'classnames';

import { useLocation        ,
         useNavigate        }  from 'react-router-dom';

import { Dispatch           }  from 'redux';
import { useDispatch        }  from 'react-redux';

import   styles                from './home.module.scss';

import { GlobalMenu         }  from '../../menu/global/global';
import { Footer             }  from '../../menu/footer/footer';

import { TopShifter         }  from '../../menu/top_shifter/top_shifter';
import { CookiesManager     }  from '../../cookie/manager';

import { Part01             }  from './blocks/part_01/part_01';
import { Part02             }  from './blocks/part_02/part_02';
import { Part03             }  from './blocks/part_03/part_03';

import { postEvent          }  from '../../../network/api_events_stats';

import { INTERNAL_LINKS     }  from '../../../network/urls';

import { useTypedSelector   }  from '../../../datas/useTypeSelector';

import { initCart           }  from '../../../datas/cartManagment/actionCreators';
import { initAuthentication }  from '../../../datas/authenticationManagment/actionCreators';
import { initUser           }  from '../../../datas/userManagment/actionCreators';

import { Window             }  from '../../widget/window/window';

export const Home = () => {

  const { authentication } = useTypedSelector ( (state) => state.authenticationManagment );

  const location = useLocation();
  
  const dispatch: Dispatch<any> = useDispatch()
  const navigate                = useNavigate();
  
  const [ alertWindowDeprecatedTokenVisible, setAlertWindowDeprecatedTokenVisible] = useState ( false );

  useEffect(() => {

    window.scroll(0, 0);

	postEvent ( "__OPEN__:" + window.location.pathname + window.location.search + window.location.hash , authentication.login );

    var identifiant = location.hash.substring(1);
   
     if ( identifiant === "" )
      {   
	   identifiant = "accueil";
      }

     var element = document.getElementById( identifiant );

     if ( identifiant !== "accueil" )
           {
            if ( element !== null )
             {	 
              element.scrollIntoView (); //({ behavior: 'smooth', block: "end", inline: "nearest" })	 
  	         }
	       }
	  else {
            if ( element !== null )
             {	 
	          element.scrollTo(0, 70);
	         }
	       }
  }); 

  const showAlertWindowDeprecatedToken = () => {
  
    setAlertWindowDeprecatedTokenVisible ( true );
  }

  const closeAlertWindowDeprecatedTokenWithCancelButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );
	
	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Home}` );
   }

  const closeAlertWindowDeprecatedTokenWithValidButton = () => {

    setAlertWindowDeprecatedTokenVisible ( false );

	dispatch ( initUser  () );
	dispatch ( initAuthentication (true) );
	dispatch ( initCart () );

    navigate ( `${INTERNAL_LINKS.Login}` );
  }
 
  return (
            <>

 		      <CookiesManager />
 			  <GlobalMenu />
              <TopShifter/>

			  
              <div className={classNames(styles.root)} id="home">

			  {
	           alertWindowDeprecatedTokenVisible 
			   &&
	           ( 
			    < Window message               = { <><span className = {classNames(styles.bold_text)}>Votre session a expiré, vous devez vous reconnecter.</span><br/><br/>Confirmer ?<br/></> }
                         messageType           = "normal"
                         onClickOnCancelButton = {closeAlertWindowDeprecatedTokenWithCancelButton}
                         onClickOnValidButton  = {closeAlertWindowDeprecatedTokenWithValidButton}
                         cancelButtonName      = "NON"
                         validButtonName       = "OUI"   /> 
			   )
	          }
		 

              {/*
                 <section id="spot">
				  <Spot />
				 </section>
               */}

                 <section id="part01">
				  <Part01 />
				 </section>

                 <section id="part02">
				  <Part02 />
				 </section>

                 <section id="part03">
				  <Part03 />
				 </section>

              </div>			 

			<Footer/>

            </>
        );
};

export default Home;
