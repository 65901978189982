import   React                         , 
       { useEffect                     ,
	     useState                      ,
		 useRef                        }  from 'react';

import { useNavigate                   }  from 'react-router-dom';

import { Dispatch                      }  from 'redux';
import { useDispatch                   }  from 'react-redux';

import   classNames                       from 'classnames';

import   styles                           from './highlighted_elements.module.scss';

import { Advert } from './advert';

import { Video, HighlightedVideo }  from '../../../../../datas/videoManagment/actionTypes'
import { getHighlightedVideoList }  from '../../../../../network/api_highlighted_video'

export interface HightlightedElementsProps {
  showSheet: ( video: Video ) => void
  showAlertWindowDeprecatedToken: () => void;			   
}

export const HightlightedElements = ({ showSheet ,
                                       showAlertWindowDeprecatedToken }: HightlightedElementsProps) => {
						 
  const emptyList: HighlightedVideo[] = []
  
  const [ highlightedVideoList, setHighlightedVideoList ] = useState (emptyList)
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
      console.log (highlightedVideoList)

    init (); 
  }, []);

  useEffect(() => {
  
    console.log (highlightedVideoList)
  }, [highlightedVideoList]);

  const init = async () => {
  
    const result = await getHighlightedVideoList ()
	
	console.log (result)
	
	setHighlightedVideoList (result.highlightedVideos)
  }

  const handleAdvertComplete = () => {
  
    setCurrentIndex((prevIndex) => (prevIndex + 1) % highlightedVideoList.length);
  };
  
  return (
 
   
    <div className = {classNames (styles.root )}>

      { 
	    highlightedVideoList[currentIndex] 
		&& 
		(
          <Advert showSheet        = {showSheet}
                  onAdvertComplete = {handleAdvertComplete}   
                  highlightedVideo = {highlightedVideoList[currentIndex]} 
				  showAlertWindowDeprecatedToken = {showAlertWindowDeprecatedToken} />
		)
	  }
	
    </div>
 
  );
};

export default HightlightedElements;

