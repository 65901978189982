const MODE_TEST: boolean = false;


const WWW_BASE_URL   = 'https://lovelight.tv';
const API_BASE_URL   = 'https://leitz.lovelight.tv';
const MEDIA_BASE_URL = 'https://media.lovelight.tv';
const LIVE_BASE_URL  = 'https://live.lovelight.tv';

/*
const WWW_BASE_URL   = 'https://custom-front.preprod.lovelightfactory.fr';
const API_BASE_URL   = 'https://custom-api.preprod.lovelightfactory.fr';
const MEDIA_BASE_URL = 'https://media.lovelight.tv';
const LIVE_BASE_URL  = 'https://live.lovelight.tv';
//const LIVE_BASE_URL  = 'https://live.backdoor06.lovelightfactory.fr';

/*
const WWW_BASE_URL   = 'https://custom-front.backdoor06.lovelightfactory.fr';
const API_BASE_URL   = 'https://custom-api.backdoor06.lovelightfactory.fr';
const MEDIA_BASE_URL = 'https://media.lovelight.tv';
const LIVE_BASE_URL  = 'https://live.backdoor06.lovelightfactory.fr';
*/

//const TEST         = 'https://webhook.site/c8b5a7a4-62cf-4890-b52d-9317de576246';
const TEST         = 'https://01hmz6fc8we4p1x6901w664n1q10-cd8f448eeecae42b0655.requestinspector.com/';

export const API_URLS: Record<string, string> = {

  getBroadcastingChannelPackageInfos:           ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/broadcasting_channel_package`                 ),

  postAuthenticationResult:                     ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/authentication_result`                        ),
  getUserEmailReset:                            ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/email_reset`                                  ),
  getUserEmailValidation:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/email_validation`                             ),
  getUserPasswordReset:                         ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/password_reset`                               ),
  getUserPasswordResetRequest:                  ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/password_reset_request`                       ),
  getUserExistenceCheck:                        ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/user_existence_check`                         ),
  postUser:                                     ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/users`                                        ),
  getUserEmailResetRequest:                     ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/email_reset_request`                      ),
  getUserEmailValidationCheck:                  ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/email_validation_check`                   ),
  getUserInfosByEmail:                          ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/user_infos_by_email`                      ),
  patchUserInformations:                        ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/users/{id}`                               ),
  patchUserDiscoverOfferChoiceAtCreation:       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/users/{id}/discover_offer_choice`           ),
  patchUserCurrentOfferTermination:             ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/users/{id}/terminate_current_offer`         ),
  patchUserCurrentOfferTerminationCancellation: ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/users/{id}/cancel_current_offer_termination` ),
  patchUserAccountClosure:                      ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/users/{id}/account_closure`                ),

  postBannedIpAddress:                          ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/banned_ip_addresses`                          ),
  getBannedIpAddressCheck:                      ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/banned_ip_addresses`                          ),

  postCustomerFrontEvent:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/customer_front_events`                        ),

  postPayment:                                  ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/payments`                                 ),
  getPaymentInfosByEmail:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/payment_infos_by_email`                   ),

  postRgpdConsentLog:                           ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/rgpd_consent_logs`                            ),

  getProductInfos:                              ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/product_infos`                                ),
  getProductCatalogInfos:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/product_categories`                           ),

  getCatalogInfos:                              ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/video_categories`                             ),
  getAdditionnalVideoInformations:              ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/videos/{id}/additional_informations`          ),
  getInformationsFromYoutube:                   ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/informationsFromYoutube`                      ),
  getVideoViewsNumberRanking:                   ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/video_views_number_ranking`                   ),

  getHighlightedVideoList:                      (  MODE_TEST ? TEST : `${API_BASE_URL}/api/public/highlighted_video_list`                      ),


  postViewerNotification:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/video_viewing_logs`                           ),  // `${API_BASE_URL}/api/public/viewer_notifications`         ) ,

  postPurchase:                                 ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchases`                                ),
  postMergeWithUserCart:                        ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/merge_with_user_card`                     ),
  getPurchaseInfosByEmail:                      ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchase_infos_by_email`                  ),
  patchAddItemToUserCart:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchases/{id}/add_item`                  ),
  patchAddSpecialItemToUserCart:                ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchases/{id}/add_special_item`          ),
  patchRemoveItemToUserCart:                    ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchases/{id}/remove_item`               ),
  patchChangeQuantityOfItemInUserCart:          ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/purchases/{id}/change_quantity_of_item`   ),

  postInformationRequest:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/information_request`                          ),

  postHotlineQuestion:                          ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/hotline_question`                         ),
  patchHotlineQuestion:                         ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/hotline_question/{id}`                    ),
  getHotlineQuestionsByEmail:                   ( MODE_TEST ? TEST : `${API_BASE_URL}/api/restricted/hotline_questions_by_email`               ),

  getLastModifiedVersion:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/api/public/front_last_version`                           ),

  postUserAuthentication:                       ( MODE_TEST ? TEST : `${API_BASE_URL}/auth`                                                    ),
  
};

const accountUrl = "/compte";

export const INTERNAL_LINKS: Record<string, string> = {
  Home:                          `/`,
  Login:                         "/connexion",           
  AccountManagment:              "/compte",
  AccountManagment_MyAccount:    `${accountUrl}/#mon-compte`,
  AccountManagment_Subscription: `${accountUrl}/#abonnements-et-services`,
  AccountManagment_Payments:     `${accountUrl}/#informations-de-paiement`,
  AccountManagment_Hotline:      `${accountUrl}/#hotline`,
  Registration:                  "/inscription",
  Subscription:                  "/abonnement",
  Download:                      "/telechargement",
  Club:                          "/club",
  PasswordReset:                 "/reinitialisation-mot-de-passe",
  EmailReset:                    "/reinitialisation-email",
  PaymentFail:                   "/echec-paiement",
  PaymentSuccess:                "/confirmation-paiement",
  AccountCreationSuccess:        "/confirmation-creation-compte",
  Replay:                        "/grille",
  Shop:                          "/boutique",
  Cart:                          "/panier",
  Streaming:			         "/diffusion",
  Masterclass:			         "/masterclasses",
  VideoViewer:                   "/video",
  LegalNotice:                   "/mentions-legales",
  TermsOfService:                "/conditions-generales-d-utilisation",
  CookiesInformation:            "/notice-d-information-sur-les-cookies",
  PersonnalDatasInformation:     "/notice-d-information-sur-les-donnees-personnelles",
  Logo_Visa:                     "/images/logos/Visa_50.png",
  Logo_Mastercard:               "/images/logos/Mastercard_50.png",
  Logo_Carte_bleue:              "/images/logos/Carte_bleue_50.png",
  Logo_PayPal:                   "/images/logos/PayPal_50.png",
  Logo_Axepta:                   "/images/logos/Axepta_50.png",
  Logo_Amex:                     "/images/logos/Amex.png",
};

export const WWW_URLS: Record<string, string> = {

  Home:                          `${WWW_BASE_URL}${INTERNAL_LINKS.Home}`,
  Login:                         `${WWW_BASE_URL}${INTERNAL_LINKS.Login}`,    
  AccountManagment:              `${WWW_BASE_URL}${INTERNAL_LINKS.AccountManagment}`,
  AccountManagment_MyAccount:    `${WWW_BASE_URL}${INTERNAL_LINKS.AccountManagment_MyAccount}`,
  AccountManagment_Subscription: `${WWW_BASE_URL}${INTERNAL_LINKS.AccountManagment_Subscription}`,
  AccountManagment_Payments:     `${WWW_BASE_URL}${INTERNAL_LINKS.AccountManagment_Payments}`,
  Registration:                  `${WWW_BASE_URL}${INTERNAL_LINKS.Registration}`,
  Subscription:                  `${WWW_BASE_URL}${INTERNAL_LINKS.Subscription}`,
  Download:                      `${WWW_BASE_URL}${INTERNAL_LINKS.Download}`,
  Club:                          `${WWW_BASE_URL}${INTERNAL_LINKS.Club}`,
  PasswordReset:                 `${WWW_BASE_URL}${INTERNAL_LINKS.PasswordReset}`,
  EmailReset:                    `${WWW_BASE_URL}${INTERNAL_LINKS.EmailReset}`,
  PaymentFail:                   `${WWW_BASE_URL}${INTERNAL_LINKS.PaymentFail}`,
  PaymentSuccess:                `${WWW_BASE_URL}${INTERNAL_LINKS.PaymentSuccess}`,
  AccountCreationSuccess:        `${WWW_BASE_URL}${INTERNAL_LINKS.AccountCreationSuccess}`,
  Replay:                        `${WWW_BASE_URL}${INTERNAL_LINKS.Replay}`,
  Shop:                          `${WWW_BASE_URL}${INTERNAL_LINKS.Shop}`,
  Cart:                          `${WWW_BASE_URL}${INTERNAL_LINKS.Cart}`,
  Streaming:			         `${WWW_BASE_URL}${INTERNAL_LINKS.Streaming}`,
  Masterclass:			         `${WWW_BASE_URL}${INTERNAL_LINKS.Masterclass}`,
  LegalNotice:                   `${WWW_BASE_URL}${INTERNAL_LINKS.LegalNotice}`,
  TermsOfService:                `${WWW_BASE_URL}${INTERNAL_LINKS.TermsOfService}`,
  CookiesInformation:            `${WWW_BASE_URL}${INTERNAL_LINKS.CookiesInformation}`,
  PersonnalDatasInformation:     `${WWW_BASE_URL}${INTERNAL_LINKS.PersonnalDatasInformation}`,
  Logo_Visa:                     `${WWW_BASE_URL}${INTERNAL_LINKS.Logo_Visa}`,
  Logo_Mastercard:               `${WWW_BASE_URL}${INTERNAL_LINKS.Logo_Mastercard}`,
  Logo_Carte_bleue:              `${WWW_BASE_URL}${INTERNAL_LINKS.Logo_Carte_bleue}`,
  Logo_PayPal:                   `${WWW_BASE_URL}${INTERNAL_LINKS.Logo_PayPal}`,
  ProductThumbnail:              `${MEDIA_BASE_URL}/Boutique/`,
  Thumbnail:                     `${MEDIA_BASE_URL}/Vignettes/`,
  Video:                         `${MEDIA_BASE_URL}/Videos/`,
  TV:                            `${MEDIA_BASE_URL}/TV/`,
 // Live:                          `${LIVE_BASE_URL}/LovelightTV01/stream.m3u8`
//  Live:                          `http://192.168.1.8:8888/canal1/stream.m3u8` //`${LIVE_BASE_URL}/mystream/stream.m3u8`
 // Live:                          `http://192.168.1.8:8888/lovelighttv_main/stream.m3u8` //`${LIVE_BASE_URL}/mystream/stream.m3u8`
 // Live:                          `${LIVE_BASE_URL}/lovelighttv_main/stream.m3u8`
 Live:                          `${LIVE_BASE_URL}/mystream/stream.m3u8`
};

/*

npx create-react-app front --template typescript

npm i react-router-dom
npm i classnames
npm i date-fns
npm i sass
npm i redux
npm i react-redux
npm i redux-thunk
npm i axios

npm i react-tabs
npm i react-accessible-accordion
npm i react-burger-menu @types/react-burger-menu
npm i ua-parser-js @types/ua-parser-js
npm i @szhsin/react-menu
npm i react-ga4

*/
